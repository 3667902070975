<!-- <div class="modal-header">
  <h3 id="dialog-sizes-name1" class="modal-title pull-left mb-0">
    <strong>
    </strong>
  </h3>
  <button type="button" class="close pull-right" (click)="dismiss()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->
<div class="modal-body">
  <h2 class="title text-center mb-4" [innerHTML]="title"></h2>
  <p class="text-center" [innerHTML]="paragraph"></p>
</div>
<div class="modal-footer justify-content-around">
  <button class="btn btn-primary-outline" (click)="decline()">Cancelar</button>
  <button class="btn btn-primary-clear" (click)="confirm()">Confirmar</button>
</div>
