import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import localeCl from '@angular/common/locales/es-CL';
import { registerLocaleData } from '@angular/common';

import { ToastrModule } from 'ngx-toastr';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/auth.interceptor';
import { ToastComponent } from './modules/shared/components/toast/toast.component';
import { ModalConfirmationComponent } from './modules/shared/components/modal-confirmation/modal-confirmation.component';

registerLocaleData(localeCl);

// export function initializeApp(encryptService: EncryptService): any {
//   return (): Promise<any> => {
//     return encryptService.Init();
//   };
// }

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 30000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-CL'
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-CL'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [EncryptService], multi: true },

  ],
  entryComponents: [
    ToastComponent,
    ModalConfirmationComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
