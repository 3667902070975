import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/auth.guard';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'pass', loadChildren: () => import('./modules/recover-password/recover-password.module').then(m => m.RecoverPasswordModule) },
  { path: 'page', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule), canActivate: [AuthGuard] },
  { path: 'usuarios', loadChildren: () => import('./modules/user-manager/user-manager.module').then(m => m.UserManagerModule), canActivate: [AuthGuard] },
  { path: 'cargar', loadChildren: () => import('./modules/data-upload/data-upload.module').then(m => m.DataUploadModule) },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
