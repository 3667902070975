import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private router: Router ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('🚀 ~ file: auth.interceptor.ts ~ line 38 ~ AuthInterceptor ~ intercept ~ request.url', request.url, )
    let token = localStorage.getItem('access_token') || '';

    if (request.headers.get('Authorization')) {
      token = request.headers.get('Authorization');
    } else {
      token = `Bearer ${token}`;
    }

    const authReq = request.clone({
      headers: request.headers
        .set('Authorization', token)
    });

    return next.handle(authReq).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse && err.status === 0) {
        } else if (err instanceof HttpErrorResponse && err.status === 401) {
          localStorage.removeItem('access_token');
          this.router.navigate(['/login?error=auth']);
        }
        return throwError(err);
      })
    );
  }
}
