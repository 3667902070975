import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as crypto from 'asymmetric-crypto';
import { IEncryptedData, IKeyPair } from 'src/app/core/interfaces/encrypt.interface';
import { IHttpResponse } from 'src/app/core/interfaces/htttp-response.interface';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  private publicKey: string;
  private keyPair: IKeyPair;

  constructor( private http: HttpClient ) {
    this.keyPair = crypto.keyPair();
    this.pair();
  }

  Init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.pair().then(() => {
        resolve();
      }).catch(
        (error) => {
          reject(error);
        }
      );
    });
  }

  public pair(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.publicKey) {
        resolve();
      } else {
        this.http.get(`${environment.API_URL}${environment.API_V1}auth/login/pair`).toPromise()
        .then((resp: IHttpResponse) => {
          this.publicKey = resp.data;
          resolve(this.publicKey);
        })
        .catch((error) => {
          console.error('Tenemos problemas para conectar con el servidor', error);
          reject(error);
        });
      }
    });
  }

  public encrypt(body: any = {}): any {
    return {
      encrypted: crypto.encrypt(JSON.stringify(body), this.publicKey, this.keyPair.secretKey),
      publicKey: this.keyPair.publicKey
    };
  }

  public decrypt(encrypted: IEncryptedData ): any {
    return crypto.decrypt(encrypted.data, encrypted.nonce, this.publicKey, this.keyPair.secretKey);
  }

  public handleDataEncryption(data: any): any {
    if (environment.ENCRYPT) {
      data = this.encrypt(data);
    }
    return data;
  }

  public handleDataDecryption(data: IEncryptedData): any {
    if (environment.ENCRYPT) {
      data = this.decrypt(data);
    }
    return data;
  }
}
