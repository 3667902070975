import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncryptService } from './encrypt.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private encryptService: EncryptService,
  ) { }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  public setToken(token: string): void {
    localStorage.setItem('access_token', token);
  }

  public getUserFromToken(token: string): any {
    console.log('🚀 ~ file: auth.service.ts ~ line 28 ~ AuthService ~ getUserFromToken ~ token', token)
    if (!token) {
      token = this.getToken();
    }
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(token);
    return decodedToken;
  }

  public isAuthenticated(): boolean {
    const jwtHelper = new JwtHelperService();
    const token = this.getToken();
    return token ? !jwtHelper.isTokenExpired(token) : false;
  }


  public login(params: { username: string, password: string }): Promise<any> {
    let body = {
      email: params.username,
      password: params.password
    };
    if (environment.ENCRYPT) {
      body = this.encryptService.encrypt(body);
    }
    return this.http.post(`${environment.API_URL}${environment.API_V1}auth/login`, body)
    .toPromise();
  }

  public recoverPassword(email: string): Promise<any> {
    const body = {
      email
    };
    return this.http.post(`${environment.API_URL}${environment.API_V1}forgot`, body).toPromise();
  }

  public restorePassword(token: string, params: { newPassword:string, confirmPassword: string }): Promise<any> {
    let body = {
      password: params.newPassword,
      confirmation: params.confirmPassword
    };
    if (environment.ENCRYPT) {
      body = this.encryptService.encrypt(body);
    }
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.patch(`${environment.API_URL}${environment.API_V1}forgot/reset`, body, { headers }).toPromise();
  }

}
