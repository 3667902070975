import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit {

  public title: string;
  public paragraph: string;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  public dismiss(): void {
    this.bsModalRef.hide();
  }

  public confirm(): void {
    if (this.bsModalRef.content.callback != null){
      this.bsModalRef.content.callback(true);
      this.bsModalRef.hide();
    }
  }

  public decline(): void {
    if (this.bsModalRef.content.callback != null){
      this.bsModalRef.content.callback(false);
      this.bsModalRef.hide();
    }
  }

}
